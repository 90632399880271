import {Media, MediaThumbnail} from "@shopware-pwa/types";
import {ref} from "vue";

export default function useMediumThumbnail(
    media: Media | undefined
): string {
    const url = ref(<any>null);

    if (media) {
        if (media.thumbnails && media.thumbnails.length > 1) {
            const thumbnails = media.thumbnails.sort((a, b) => {
                return a.w - b.w;
            });
            url.value = thumbnails[1].url;
        } else if (media.thumbnails && media.thumbnails.length > 0) {
            url.value = media.thumbnails[0].url;
        }
    }
    if (!url.value && media && media.url) {
        url.value = media.url;
    }

    return url.value;
}
